/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

html,
body {
  margin: 0;
  font-family: "San Francisco", sans-serif;
  font-weight: 400;
  scroll-behavior: smooth;
  overflow-x: hidden;
  background-color: var(--bg-color);
}

#open-sesame {
  display: none;
}

#mobhide{
  display: none;
}


#showcase-my-works, #showcase-my-works-secret{
  display: none;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
     transform: translateX(-2px);
}
::-moz-placeholder { /* Firefox 19+ */
       transform: translateX(-2px);

}
:-ms-input-placeholder { /* IE 10+ */
       transform: translateX(-2px);
}


#arrow123{
  margin: auto;
  float: center;   
  display: flow-root;
}


#stanz{
  right: -274.5px;
  bottom: -130px;
  height:auto;
  fill: #1C57DA;
  opacity: 0; 
  transition: all 0.25s ease;
  z-index: 1;
}

#stanz.bominggo{
  transition-delay: 0.05s;
  opacity: 1;
  display: block;
  right: -274.5px;
  bottom: -135px;
  position: absolute;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*
::-webkit-scrollbar {
  width: 0px;  
  background: transparent;  
}
::-webkit-scrollbar-thumb {
  background: #FF0000;
}

*/

/*
  .scrollable-element {
    scrollbar-color: var(--day-color) var(--text-link-colors);
  }
*/

.hiddenclass {
  display: none;
}

#confetti {
  opacity: 0.0;
  position: fixed;
  left: 0;
  top: 0;
  -webkit-transition: all 5s;
  -o-transition: all 5s;
  transition: all 5s;
}

#confetti.opa {
  opacity: 1.0;
}

.header {
  margin-top: 20px;
  width: 100%;
  opacity: 0.0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header.opac {
  opacity: 1.0;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}


@-webkit-keyframes confetti-ani {
  0% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}


@keyframes confetti-ani {
  0% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}




strong {
  font-weight: 500;
}





a {
  text-decoration: none;
  color: black;
}

.page {
  margin-bottom: 0px;
  margin-top: 50px;
  width: 100%;
  position: relative;
  -webkit-transition: 1s all;
  -o-transition: 1s all;
  transition: 1s all;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transform: translateY(200px);
  -ms-transform: translateY(200px);
  transform: translateY(200px);
  opacity: 0.0%;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.page.move {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1.0;

}

.omri{
  margin-top: -90vh;
}

.omri > *:first-child{
  width: 100%; 
  height: 90vh;
}

.group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.element {
  position: relative;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

.hidden {
  display: none;
}

.message-block {
  line-height: 26px;
  position: relative;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
}

.message-block-last {
  margin-bottom: 15px;
}

.right .message,
.left .message {
  position: relative;
  transition: 1s opacity, all 0.4s;
  -webkit-transition: 1s opacity, all 0.4s;
  -moz-transition: 1s opacity, all 0.4s;
  -ms-transition: 1s opacity, all 0.4s;
  -o-transition: 1s opacity, all 0.4s;
  opacity: 0.0;
}

@-webkit-keyframes bubbleleft {
  0% {
    opacity: 0.0;
    -webkit-transform: rotate(-45deg) scaleX(-1);
    transform: rotate(-45deg) scaleX(-1);
    left: -3.5px;
  }

  100% {
    opacity: 1.0;
    -webkit-transform: rotate(0) scaleX(-1);
    transform: rotate(0) scaleX(-1);
  }
}

@keyframes bubbleleft {
  0% {
    opacity: 0.0;
    -webkit-transform: rotate(-45deg) scaleX(-1);
    transform: rotate(-45deg) scaleX(-1);
    left: -3.5px;
  }

  100% {
    opacity: 1.0;
    -webkit-transform: rotate(0) scaleX(-1);
    transform: rotate(0) scaleX(-1);
  }
}

@-webkit-keyframes bubbleRight {
  0% {
    opacity: 0.0;
    -webkit-transform: rotate(45deg) scaleX(1);
    transform: rotate(45deg) scaleX(1);
    right: -4.5px;
  }

  100% {
    opacity: 1.0;
    -webkit-transform: rotate(0) scaleX(1);
    transform: rotate(0) scaleX(1);
  }
}

@keyframes bubbleRight {
  0% {
    opacity: 0.0;
    -webkit-transform: rotate(45deg) scaleX(1);
    transform: rotate(45deg) scaleX(1);
    right: -4.5px;
  }

  100% {
    opacity: 1.0;
    -webkit-transform: rotate(0) scaleX(1);
    transform: rotate(0) scaleX(1);
  }
}

.left[data-emergence='visible']>svg {
  display: block;
  position: absolute;
  left: -15.5px;
  bottom: -1px;
  -webkit-transform: rotate(-45deg) scaleX(-1);
  -ms-transform: rotate(-45deg) scaleX(-1);
  transform: rotate(-45deg) scaleX(-1);
  -webkit-animation: bubbleleft 0.6s;
  animation: bubbleleft 0.6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  fill: var(--left-svg-fill);
  opacity: 0.0;
}

.right[data-emergence='visible']>svg {
  display: block;
  position: absolute;
  right: -16.5px;
  bottom: -2px;
  -webkit-transform: rotate(45deg) scaleX(1);
  -ms-transform: rotate(45deg) scaleX(1);
  transform: rotate(45deg) scaleX(1);
  -webkit-animation: bubbleRight 0.6s;
  animation: bubbleRight 0.6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  fill: #0253e2;
  opacity: 0.0;
}

.right[data-emergence='hidden']>svg, .left[data-emergence='hidden']>svg {
  display: none;
}

.message-block .show {
  opacity: 1.0;
}

.right[data-emergence=hidden] .message {
  left: 500px;
}

.right[data-emergence=visible] .message {
  left: 0;
  opacity: 1.0;
}

.left[data-emergence=hidden] .message {
  left: -500px;
}

.left[data-emergence=visible] .message {
  left: 0px;
  opacity: 1.0;
}

.message-block {
  display: block;
  width: 100%;
  max-width: 675px;
  /* max-width: 860px; */
  margin-bottom: 10px;
}

.message {
  border-radius: 30px;
  display: inline-block;
  padding: 15px 25px;
  line-height: 34px;
  font-size: 24px;
}

.right .message {
  background-color: #0253e2;
  color: white;
  float: right;
}

.left .message {
  color: var(--left-message-color);
  background-color: var(--left-message-bg-color);
}


.button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 75px;
  margin-bottom: 75px;
}


.btn {
  margin: 0 10px;
  background-color: transparent;
  padding: 10px 20px;
  border: var(--btn-border);
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  font-size: 20px;
  color: var(--btn-text-color);
  border-radius: 50px;
}

.btn:hover {
  background-color: var(--btn-bg-color-hover);
  cursor: pointer;
}

.btn:focus {
  border-color: var(--border-color);
  outline: none;
}






.profile {
  z-index: 2;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  mix-blend-mode: multiply;
  opacity: 0.0%;
  margin-top: 25%;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 200px;
  width: 200px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.profile.animate {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1.0;
}

.profile.animate.smaller {
  margin-top: 23px;
  position: relative;
  display: block;
  left: 2%;
  width: 90px;
  height: 90px;
}

#day {
  margin-top: 100px;
  color: var(--day-color);
  font-size: 17px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 170px;
}

#currentTime {
  font-weight: 300;
}

#location {
  margin-top: 60px;
  color: var(--location-color);
  font-size: 14px;
  text-align: center;
  font-family: sans-serif;
  font-weight: 300;
}

.text-link-colors {
  color: var(--text-link-colors);
}

.light-text-link-colors {
  color: var(--text-link-colors);
  opacity: 0.5;
}

.showcase {
  /* left: 0 !important; */
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.showcase-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: start;
  align-self: flex-start;
}


.box {
  position: relative;
  display: block;
  /*margin-right: 20px;
  margin-left: 20px; */
  opacity: 0.0;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
}

.box.load {
  opacity: 1.0;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.box.a {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
  ;
}

.box.b {
  -webkit-transition-delay: 1.05s;
  -o-transition-delay: 1.05s;
  transition-delay: 1.05s;
  ;
}

.box.c {
  -webkit-transition-delay: 1.60s;
  -o-transition-delay: 1.60s;
  transition-delay: 1.60s;
  ;
}


#topopo{
  margin-top: 0%;
}

.box img {
  -webkit-filter: brightness(100%);
  margin-top: 20px;
  cursor: pointer;
  max-width: 320px;
  /* 230 */
}


.box img:hover {
  -webkit-filter: brightness(90%);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}


.gallery-h {
  font-size: 21px;
  font-weight: 500;
  color: var(--text-link-colors);
  display: block;
  width: auto;
  display: table;
  margin-bottom: 0px;
  margin-top: 12px;
  cursor: pointer;
}

.gallery-h:hover {
  opacity: 0.6;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.gallery-p {
  font-weight: 400;
  color: var(--text-link-colors);
  opacity: 0.5;
  margin-top: 4px;
  display: block;
  font-size: 16px;
  ;
}

.phone-link {
  text-decoration: underline;
  color: #007aff;
}

#pdf {
  text-decoration: underline;
  color: var(--border-color);
  cursor: pointer;
  line-height: 1;
  margin-left: auto;
  z-index: 1;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
}

#pdf::after {
  content: "👉";
  font-size: 16px;
  opacity: 0.0;
}

#pdf2 {
  color: var(--border-color);
  cursor: pointer;
  line-height: 1;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  position: absolute;
}

#pdf2:hover {
  color: var(--day-color);
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

#pdf:hover {
  color: var(--day-color);
}

#pdf2::before {
  content: "👉";
  font-size: 16px;
  opacity: 0.0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

#pdf2:hover::before {
  font-size: 16px;
  opacity: 1.0;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.skills {
  /*
  display: flex;
  flex-direction: row;
  align-items: left; */
  left: 0;
  margin: 0;
  width: 100%;
  max-width: 675px;
  position: relative;
}


.bigger {
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0.0;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  /* THE DURATION */
}


.f.triggeredCSS3 {
  opacity: 1.0;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
}

.w.triggeredCSS3 {
  opacity: 1.0;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
}

.s.triggeredCSS3 {
  opacity: 1.0;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
}

.h.triggeredCSS3 {
  opacity: 1.0;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
}

.c.triggeredCSS3 {
  opacity: 1.0;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
}

.ps.triggeredCSS3 {
  opacity: 1.0;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
}

.ae.triggeredCSS3 {
  opacity: 1.0;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
}

.pr.triggeredCSS3 {
  opacity: 1.0;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
}

.ai.triggeredCSS3 {
  opacity: 1.0;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
}

.w {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.s {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.h {
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.c {
  -webkit-transition-delay: 2s;
  -o-transition-delay: 2s;
  transition-delay: 2s;
}

.ps {
  -webkit-transition-delay: 2.5s;
  -o-transition-delay: 2.5s;
  transition-delay: 2.5s;
}

.ae {
  -webkit-transition-delay: 3s;
  -o-transition-delay: 3s;
  transition-delay: 3s;
}

.pr {
  -webkit-transition-delay: 3.5s;
  -o-transition-delay: 3.5s;
  transition-delay: 3.5s;
}

.ai {
  -webkit-transition-delay: 4s;
  -o-transition-delay: 4s;
  transition-delay: 4s;
}





@-webkit-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -ms-transform: translateY(-9%);
    -webkit-transform: translateY(-9%);
    transform: translateY(-9%);
  }

  60% {
    -ms-transform: translateY(-4.5%);
    -webkit-transform: translateY(-4.5%);
    transform: translateY(-4.5%);
  }
}





@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }

  60% {
    -ms-transform: translateY(-7.5px);
    -webkit-transform: translateY(-7.5px);
    transform: translateY(-7.5px);
  }
}

.arrow {
  opacity: 0;
  transition: opacity 0.4s ease;
  position: absolute;
  top: 91%;
  left: 50%;
  margin-left: -43px;
  width: 40px;
  height: 40px;
  font-size: 40px;
  padding: 20px;
  z-index: 99;
  background-size: 20px 15px;
  background-repeat: no-repeat;
  background-position: center;
}

.nothing{
    top: 94%;
    opacity: 0 !important;
  }

.bounce {
  -webkit-animation: bounce 1.75s infinite;
  animation: bounce 1.75s infinite;
}









@media only screen and (min-width: 1000px) {

  #day {
    font-size: 24px;
    left: 2%;
  }

  #location {
    margin-top: 100px;
    font-size: 16px;
  }

  .profile {
    margin: auto;
    margin-top: 80px;
    height: 250px;
    width: 250px;
  }

  .profile.animate.smaller {
    left: 0;
    width: 150px;
    height: 150px;
  }

  .box img {
    max-width: 240px;
  }



}

/*solving iphone 11 top image bag*/
@media only screen and (min-width: 414px) and (max-width: 430px) {
  #topopo{
    margin-top: 22% !important;
  }
}

/*solving desktop 2k bag*/
@media only screen and (min-height: 1150px) and (max-height: 1200px) {
  #topopo{
    margin-top: 4% !important;
  }
}
/*solving desktop 2k bag*/
@media only screen and (min-height: 1201px) and (max-height: 1260px) {
  #topopo{
    margin-top: 6% !important;
  }
}

/*solving desktop 2k bag*/
@media only screen and (min-height: 1261px) and (max-height: 1300px) {
  #topopo{
    margin-top: 8% !important;
  }
}

/*hand emoji foe huge screens*/
@media only screen and (min-height: 1300px){
  .arrow{
    top: 85%;
  }
}



@media only screen and (min-width: 1440px) {

  .message-block {
    max-width: 860px;
  }

  .skills {
    max-width: 860px;
  }

  .box img {
    margin-top: 25px;
    cursor: pointer;
    max-width: 295px;
    /* 250 */
  }
}


/** Ultra Light */
@font-face {
  font-family: "San Francisco";
  font-weight: 100;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-ultralight-webfont.woff");
}




/** Thin */
@font-face {
  font-family: "San Francisco";
  font-weight: 200;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-thin-webfont.woff");
}

/** Regular */
@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}

/** Medium */
@font-face {
  font-family: "San Francisco";
  font-weight: 500;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.woff");
}

/** Semi Bold */
@font-face {
  font-family: "San Francisco";
  font-weight: 600;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-semibold-webfont.woff");
}

/** Bold */
@font-face {
  font-family: "San Francisco";
  font-weight: 700;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-bold-webfont.woff");
}


/* Dark Mode */


:root {
  --bg-color: white;
  --border-color: #999999;
  --btn-bg-color-hover: #dddddd;
  --btn-bg-color: transparent;
  --btn-border: 1px solid #dddddd;
  --location-color: black;
  --day-color: #575757;
  --left-message-bg-color: #e5e4e9;
  --left-message-color: black;
  --left-svg-fill: #e5e4e9;
  --btn-text-color: black;
  --text-link-colors: black;
  --opacity-a: 0;
}



[data-theme="dark"] {
  --bg-color: black;
  --border-color: #5e5e5e;
  --btn-bg-color-hover: #202020;
  --btn-border: 1px solid #3f3f3f;
  --location-color: white;
  --day-color: #D6D6D6;
  --left-message-bg-color: #26252a;
  --left-message-color: white;
  --left-svg-fill: #26252a;
  --btn-text-color: white;
  --text-link-colors: white;
  --opacity-a: 1;
}

.theme-switch input {
  display: none;
}

























/*  סלידר אין סופי  */



.wrapper {
  overflow: hidden;
}

.sliding-background {
  /* background: url("/docs/compare.png") repeat-x; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 7000px;
  width: 6700px;
  -webkit-animation: slide 145s linear infinite;
  animation: slide 145s linear infinite;
  height: 650px;
}

@-webkit-keyframes slide {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(-6050px, 0, 0);
    transform: translate3d(-6050px, 0, 0);
  }
}

@keyframes slide {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(-6050px, 0, 0);
    transform: translate3d(-6050px, 0, 0);
  }
}

.slide {
  height: 338px;
  margin-right: 300px;
  vertical-align: center;
}








@media screen and (max-width: 950px) {

  #mobhide{
    display: block;
  }

  .my-works.message-block.left > *:last-child{
    display: none;
  }
  

  .button-group{
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .arrow{
        opacity: 0.0;
        top: 89%;
  }

  #day {
    margin-top: 10%;
    margin-bottom: 90px;
  }

  .message-block {
    width: 88%;
  }

  .gallery-block {
    margin-left: -125px;
  }

  .left {
    margin-left: 50px;
  }

  .right {
    margin-right: 50px;
  }

  .message {
    border-radius: 1.25em;
    padding: 10px 18px;
    font-size: 17px;
    max-width: 270px;
    line-height: 20px;
  }

  .right[data-emergence='visible']>svg{
    right: -13px;

  }

  .left[data-emergence='visible']>svg {
    left: -13px;
  }

  .btn {
    padding: 7px 18px;
    font-size: 17px;
    margin: 0 4px;
  }

  .gallery-block .box {
    max-width: 300px;
    height: 500px;
  }

  .skills {
    margin-left: 4%;
  }

  #pdf2::before {
    font-size: 14px;
  }

  #pdf2 {
    margin-left: -3px;
    font-size: 14px;
  }

  #pdf::after {
    font-size: 14px;
  }

  #pdf {
    text-decoration: none;
    font-size: 14px;
  }

  .bigger {
    height: 60px;
    width: 60px;
  }

  .box img {
    max-width: 250px;
  }
}


@media screen and (max-width: 480px) {

  .omri{
    margin-top: -80vh;
  }
  
  .omri > *:first-child{
    width: 100%; 
    height: 80vh;
  }

  #pdf2 {
    margin-left: 16px;
  }

  #pdf2::before {
    content: none;
  }

  #pdf2:hover {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  #pdf {
    margin-right: 16px;
  }

  #pdf::after {
    content: none;
  }

  .skills {
    margin-left: 10%;
  }

  .profile{
    margin-top: 0%;
  }

  #topopo{
    margin-top: 25%;
  }
  .left {
    margin-left: 12px;
  }

  .right {
    margin-right: 12px;
  }

  .showcase {
    justify-content: center;
    align-items: center;
    width: 10px;
    height: 300px;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 430px;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    margin-top: -30px;
    min-height: 250px;
  }

  .box img {
    max-width: 265px;
  }


.showcase-box {
  display: inline-flex;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

 
  .box.a {
    margin-left: 20px !important;
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }

  .box.b {
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }

  .box.c {
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
  }

  .showcase-box:last-child {
    margin-right: 16px;
  } 


  .box {
    margin-left: 0px;
    margin-right: 16px;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    margin-right: 0px;
  }

  .sliding-background {
    height: 400px;
    -webkit-transform: scale(0.85);
    -ms-transform: scale(0.85);
    transform: scale(0.85);
  }
}

































.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  z-index: 2000;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 6px;
  width: 200px;
  background: #fff;
  /*border: 1px solid #29d;*/
  background: #DFDFDF;
  border-radius: 80px;
  overflow: hidden;
}

.pace .pace-progress {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  max-width: 200px;
  position: fixed;
  z-index: 2000;
  display: block;
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  width: 100%;
  background: #2790E3;
  border-radius: 80px;
}

.pace.pace-inactive {
  display: none;
}



